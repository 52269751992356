@use "/src/scss/partials/vars";

@function remove-opacity($color) {
	@return rgba(red($color), green($color), blue($color), 1);
}
.registration{
	width: 70%;
	margin: auto;
	padding: 15px;
	background: var(--app-secondary);
	color: var(--app-textColor);
	border-radius: var(--app-radius);

	@media screen and (max-width: 660px) {
		width: 99%;
	}
}
.select-search-container {
	width: 100%;
	max-width: 100%;
	color: var(--app-textColor) !important;
}

.select-search-input, .registration select {
	height: 45px;
	vertical-align: top;
	margin: 0;
	padding: 0.2rem 1rem !important;
	border-radius: var(--app-radius);
	background: RGBA(var(--app-secondary-rgb) / 50%) !important;
	color: var(--app-textColor) !important;
	border: 1px solid RGBA(var(--app-textColor-rgb) / 20%) !important;
	border-radius: var(--app-radius);
	font-weight: 500;
	font-size: 14px;
	outline: none;
	width: 100%;

	&::placeholder {
		color: var(--app-textColor);
	}

	&:hover {
		//border-left: 2px solid RGB( var(--app-primary-rgb) / 60% );
		background: var(--app-light);
	}

	&:focus {
		background: var(--app-light);
		//border-left: 2px solid var(--app-primary);
	}
}
.registration select {
	width: unset;
}
.registration select.PhoneInputCountrySelect {
	width: 100%;
}
.registration__choice{
	width: 100%;
	padding: 10px;
	border-radius: var(--app-radius);
	border: 1px solid RGBA(var(--app-textColor-rgb) / 10%);
	display: flex;
	flex-direction: row;
	border-radius: 4px;
	margin: 5px;
	cursor: pointer;

	.registration__choice-checked{
		color: var(--app-primary);
		margin-right: 10px;;
	}
	.registration__choice-unchecked {
		color: var(--app-primary);
		margin-right: 10px;
		;
	}
}
.registration__ch-checked {
	border: 2px solid var(--app-primary);
}
.registration__select-wrapper{
	position: relative;
	width: 100%;

	input[type=text] {
		padding: 0px 0px !important;
	}

	.registration__select-trigger{
		padding: 5px 10px;
		display: flex;
		flex-direction: row;
		border: 1px solid RGBA(var(--app-textColor-rgb) / 10%);
		border-radius: var(--app-radius);
		overflow-x: hidden;

		.registration__select-triggerOptions{
			flex-basis: calc(100% - 50px);
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
	.registration__select-inner{
		position: absolute;
		width: 95%;
		top: 50px;
		left: 2%;
		border: 1px solid RGBA(var(--app-textColor-rgb) / 20%);
		background: RGBA(var(--app-secondary-rgb));
		border-radius: var(--app-radius);
		z-index: 99;
		max-height: 250px;
		overflow-y: auto;
		overflow-x: hidden;

		.registration__select-header{
			padding: 10px;
			display: flex;
			flex-direction: row;
		}

		.registration__choice{
			margin: 0px 0px;

			&:hover{
				background-color: var(--app-secondary);
			}
		}
		.registration__ch-checked{
			border: 1px solid RGBA(var(--app-textColor-rgb) / 10%);
			background: RGBA(var(--app-primary-rgb) / 10%)
		}
	}
}
.react-date-picker__wrapper{
	border: none !important;
}
.react-date-picker__inputGroup__divider{
	padding-top: 10px !important;
	margin: 10px !important;
	color: var(--app-primary);
}
.react-date-picker__clear-button{
	svg{
		stroke: var(--app-danger) !important;
	}
}