@use "/src/scss/partials/vars.scss";

.mvp {
	margin: 5px;
	word-break: break-word;
	width: 100%;
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
	position: relative;

	&:hover {
		border: 1px solid var(--app-textColor);
	}

	.check {
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 10;
	}
}

.mvp-selected {
	border: 1px solid var(--app-textColor);
}

.mvp-containerColumn {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		max-width: 200px;
		margin: 0px;
	}
}

.mvp-containerRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;

	.mvp {
		//max-width: 200px;
		width: 190px;
		max-width: 100%;
	}

	img {
		max-width: 100%;
		min-width: 100px;
	}
}

.mvp {
	max-width: 100%;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.mvp__info {
	-webkit-backdrop-filter: blur(14px);
	backdrop-filter: blur(14px);
	//background: RGBA(var(--app-textColor-rgb) / 20%);
	bottom: 0px;
	margin-top: -20px;
	left: 0;
	width: 100%;
	padding: 2px;

	.mvp__info-text {
		width: 100%;
		text-align: center;
		font-weight: bold;
	}

	.mvp__info-attributes {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
		align-items: center;

		.mvp__info-attributedOption {
			flex-basis: 49%;
			font-size: 14px;
			margin-top: 10px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			font-size: 12px;

			.mvp__info-attributedOptionCounter {
				text-align: left;
				margin-right: 6px;
				display: block;
				flex-basis: 30%;
			}

			.mvp__info-attributedOptionText {
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
				width: 50px;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}