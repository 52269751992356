@use "/src/scss/partials/vars";
@font-face {
	font-family: 'Rocher';
	src: url(./RocherColorGX.woff2);
}

.scratch{
	display: flex;
	flex-direction: column;
	height: 100%;
	.scratch__gift{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-webkit-backdrop-filter: blur(14px);
		backdrop-filter: blur(14px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 18px;
	}
	.scratch__wrapper{
		position: relative;
		background-color: transparent;
		width: calc(100% - 40px);
		height: 100%;
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.scratch__coverText{
		text-align: center;
		font-size: 2em;
		font-weight: bold;
	}
	.scratch__content{
		width: 100%;
		overflow: hidden;
		flex: 1;
		z-index: 15;
		margin-left: auto;
		margin-right: auto;
		background-color: transparent;
	}
	.scratch__loader {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.scratch__hand {
		pointer-events: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10;
	}
	.scratch-won {
		text-align: center;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;

		.scratch-wonConfetti{
			width: 100%;
			height: 100%;
			position: relative;
			
			.scratch-wonConfettiA{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				z-index: 10;
			}
			
		}
		.scratch-wonContent{
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 10;
		}

		.scratch-chestPrize{
			margin: auto;
			max-width: 90%;
			max-height: 400px;
			margin-top: 20px;
		}
	}
	.scratch-chestPrize-bg {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center bottom;
		width: 100%;
		flex: 1;
	}
	.scratch__header {
		flex: 0 1 10%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.scratch__footer {
		flex: 0 1 10%;
		z-index: 20;
	}
}
