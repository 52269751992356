@use "/src/scss/partials/vars.scss";

.btn.submitButton, .submitButton {
    background-color: var(--app-primary);
    border-color: var(--app-primary);
    color: var(--app-secondaryTextColor);
    padding: 0.65rem 3rem;
}
.submitButtonContainer {
    text-align: center;
    padding: 0;
    @media screen and (max-width: 660px) {
        width: 99%;
    }
}

.submitButton.disabled,
.submitButton:disabled {
    opacity: 0.5;
}

.submitButton:not(:disabled):not(.disabled):focus,
.submitButton:not(:disabled):not(.disabled):active:focus,
.show>.submitButton.dropdown-toggle:focus {
    background-color: RGB(var(--app-primary-rgb) / 90%);
    border-color: var(--app-primary);
}

.submitButton:hover {
    color: var(--app-secondaryTextColor);
    background-color: RGB(var(--app-primary-rgb) / 90%);
    border-color: RGB(var(--app-primary-rgb) / 90%);
}

.submitButton:disabled {
    background-color: RGB(var(--app-primary-rgb) / 90%);
    border-color: RGB(var(--app-primary-rgb) / 90%);
}
