@import 'vars';

.react-tabs__tab-list{
	width: 100% !important;
	border-bottom: 1px solid RGB(var(--app-textColor-rgb) / 10%) !important;
	display: flex;
}
.react-tabs__tab{
	border: none !important;
	padding: 10px 14px !important;
	background: transparent;
	flex-grow: 1;
	border-radius: 12px;
	text-align: center;

	&:hover{
		background: var(--app-secondary);
		box-shadow: 0px 3px 6px 1px RGB(38 107 193 / 8%);
	}
}
.react-tabs__tab--selected{
	background: transparent !important;
	//background: var(--secondary) !important;
	color: var(--app-primary) !important;
	font-weight: 650;
	box-shadow: 0px 3px 6px 1px RGB(38 107 193 / 8%);
	border-bottom: 2px solid var(--app-primary) !important;
}
