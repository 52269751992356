@import 'vars';
$speed: 0.3s;
$delay: ($speed * .2);
$easing: cubic-bezier(.55,0,.1,1);

.modal-overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	font-size: 15px;
	color: var(--app-textColor);
	background-color: RGB(var(--app-light-rgb) / 75%);
	// background-color: var(--app-secondary);
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
	opacity: 0;
	visibility: hidden;
	backface-visibility: hidden;
	transition: opacity $speed $easing, visibility $speed $easing;


	&.active {
		opacity: 1;
		visibility: visible;
	}
}
.modal__right{
	justify-content: right;
	height: 100% !important;
}
.modal {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	position: relative;
	background-color: white;
	max-width: 100%;
	width: 600px;
	border-radius: var(--app-radius);
	opacity: 0;
	overflow: hidden;
	visibility: hidden;
	box-shadow: 0 2px 10px rgba(#000, 0.1);
	backface-visibility: hidden;
	transition: all $speed $easing;
	height: auto;

	.modal__header{
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		padding: 5px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;

		.modal__header-title{
			flex-basis: calc(100% - 70px);
		}

		.close-modal {
			flex-basis: 60px;
			position: relative;
			cursor: pointer;
			border-radius: 50%;
			opacity: 1;
			padding: 8px 10px;
			backface-visibility: hidden;
			transition: opacity $speed $easing, transform $speed $easing;
			transition-delay: $delay;

			svg {
				width: 3em;
				height: 3em;
				cursor: pointer !important;

				path{
					fill: var(--app-textColor);
				}
			}

			&:hover{
				path{
					fill: var(--app-danger) !important;	
				}				
			}
		}
	}
	.modal-content {
		word-break: break-word;
		opacity: 0;
		backface-visibility: hidden;
		transition: opacity $speed $easing;
		transition-delay: $delay;
		background: transparent;
		width: 100%;
		border: none;
		overflow: hidden;
		height: 100%;
		position: relative;
	}
	.modal__body{
		overflow-y: auto;
		overflow-x: hidden;
		max-height: calc(100vh - 250px);
		margin-top: 70px;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		padding: 1rem;
		box-sizing: border-box;
		padding-bottom: 1rem;
		padding-top: 0rem;
		position: relative;
		padding-bottom: 70px;
		min-height: 300px;
	}
	.modal__footer{
		padding: 5px;
		overflow: hidden;
		width: 100%;
		background: var(--app-secondary);
		box-sizing: border-box;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	&.active {
		visibility: visible;
		opacity: 1;
		transform: scale(1);

		.modal-content {
			opacity: 1;
		}
	}
}
.modal--100{
	height: 100%;
}
.modal--lg{
	width: 55%;
}
.modal--xl{
	width: 70%;
}
.modal__notLogged{
	width: 100%;
	position: fixed;
	bottom: 0px;
	left: 0px;
	padding: 15px;
	background-color: var(--app-light);
	z-index: 9999;

	svg {
		position: absolute;
		left: 5px;
		top: 5px;
		width: 2em;
		height: 2em;
		cursor: pointer !important;

		path{
			fill: var(--app-textColor);
		}
	}
}
