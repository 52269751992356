.categorized__poll{
    margin: 5px;
    width: 150px;
    word-break: break-word;
    padding: 10px;
    border-radius: var(--app-radius);
    text-align: center;
    border: 2px solid transparent;

    &:hover{
        border: 2px solid var(--app-primary);
    }
}
.categorized__poll-selected{
    border: 2px solid var(--app-primary);
    background: RGBA(var(--app-primary-rgb) / 20%);
}
.categorized__category-header{
    padding: 10px;
    text-align: left;
}
.categorized__category{
    width: 100%;
}