@use "/src/scss/partials/vars.scss";

.poll__app {
    .select__control {
        background-color: var(--app-secondary) !important;
        color: var(--app-textColor);
        outline: none;
        border: none;
        box-shadow: none;

        .select__input {
            color: var(--app-textColor) !important;
        }

        .select__placeholder {
            color: var(--app-textColor) !important;
        }

        .select__multi-value {
            background-color: var(--app-secondary) !important;
            color: var(--app-textColor-rgb) !important;
            -webkit-backdrop-filter: blur(50px) !important;
            backdrop-filter: blur(50px);
            border: 1px solid RGBA(var(--app-textColor-rgb) / 20%) !important;
            padding: 5px 10px;

            .select__multi-value__label {
                color: var(--app-textColor-rgb) !important;
            }
        }
    }

    .select__menu {
        background-color: var(--app-secondary) !important;
        color: var(--app-textColor-rgb);
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);

        .select__option--is-focused {
            background-color: RGBA(var(--app-primary-rgb) / 50%) !important;
        }
    }

    .poll__element {
        padding: 15px 15px;
        border: 1px solid RGBA(var(--app-textColor-rgb) / 20%);
        border-radius: var(--app-radius);
        margin: 3px;

        // &:hover {
        //     background: RGBA(var(--app-primary-rgb) / 30%);
        //     cursor: pointer;
        // }
        &.correct {
            background-color: #c8e6c9;
            border: 2px solid #2e7d32;
            animation: correctAnimation .5s linear 1.5;
        }
        &.incorrect {
            background-color: #ef9a9a;
            border: 2px solid #c62828;
            transition: 0s;
        }

        .checked {
            display: none;
        }
    }

    .poll__element-active {
        border: 1px solid var(--app-primary);
        background: RGBA(var(--app-primary-rgb) / 30%);
        transition: 1s;
        left: 20px;

        .checked {
            display: inline-block;
            font-size: 20px;
            ;
        }
    }

    .poll__element-radio {
        border: transparent
    }

    @keyframes correctAnimation {
        0%, 100% {
            background-color: #c8e6c9;
            border-color: #2e7d32;
        }
        50% {
            background-color: #81c784;
            border-color: #1b5e20;
        }
    }

}

