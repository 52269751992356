@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Roboto&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slim-select/1.27.0/slimselect.min.css');

@import "partials/vars";
@import "partials/buttons";
@import "partials/forms";
@import "partials/typography";
@import "partials/helpers";
@import "partials/table";
@import "partials/misc";
@import "partials/dropdown";
@import "partials/tabs";
@import "partials/responsive";
@import "partials/modal";

*{
	box-sizing: border-box;
}
html{
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}
body{
	font-size: 15px;
	font-family: 'Poppins', 'Roboto', sans-serif;
	display: flex;
	flex-direction: column;
	height: 100%;
	background: var(--app-background);
	color: var(--app-textColor);
	width: 100%;
	overflow: hidden;
	overflow-y: auto;
	padding: 0rem;
	margin: 0rem;
	font-weight: normal;
}
html.stopOverscroll, body.stopOverscroll {
	overscroll-behavior: none;
}
#root{
	height: 100%;
}
.App{
	height: 100%;
}
span{
	display: inline-block;
}
a{
	text-decoration: none;
}
.page__error{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	background: RGBA(var(--app-danger-rgb) / 80%);
	z-index: 10001;
	color: var(--app-secondaryTextColor);
	padding: 10px;
	-webkit-animation: fadein .7s; /* Safari, Chrome and Opera > 12.1 */
	   -moz-animation: fadein .7s; /* Firefox < 16 */
		-ms-animation: fadein .7s; /* Internet Explorer */
		 -o-animation: fadein .7s; /* Opera < 12.1 */
			animation: fadein .7s;
}
.page__warning{
	background: RGBA(var(--app-warning-rgb) / 80%);
}
@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* MOVE TO APP */
.ql-align-right{
	text-align: right;
}
.ql-align-left{
	text-align: left;
}
.ql-align-center{
	text-align: center;
}
.logo{
	width: 170px;
	height: 50px;
	background: var(--app-logo);
	background-repeat: no-repeat;
	background-size: contain;
}
.logo__white{
	width: 170px;
	height: 50px;
	background: var(--app-logoWhite);
	background-repeat: no-repeat;
	background-size: contain;
}
.logo__dark{
	width: 170px;
	height: 50px;
	background: var(--app-logoBlack);
	background-repeat: no-repeat;
	background-size: contain;
}
.footer__loader{
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	background-color: RGBA(var(--app-secondary-rgb) / 30%);
	z-index: 999;
	-webkit-backdrop-filter: blur(14px);
	backdrop-filter: blur(14px);
	padding: 20px;
}
@font-face {
	font-family: 'IntelligentDesign';
	src: url('./IntelligentDesign.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'IntelligentDesign';
	src: url('./IntelligentDesignBold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}
