@use "/src/scss/partials/vars.scss";

.numericPrediction{
	width: 80%;
	margin: auto;


	.numericPrediction-header{
		width: 100%;
		padding: 5px;
		background: RGBA(var(--app-secondary-rgb));
		color: var(--app-secondaryTextColor);
	}
	.numericPrediction__options{
		display: flex;
		width: 100%;
		background: var(--app-secondary);


		.numericPrediction__options-wrap{
			position: relative;

			.numericPrediction__options-vs{
				width: 40px;
				height: 40px;
				position: absolute;
				top: 40%;
				left: -20px;
				border-radius: 50%;
				font-weight: bold;
				text-align: center;
				padding-top: 8px;
				background: var(--app-primary);
				color: var(--app-secondaryTextColor);
				box-shadow: 0px 3px 6px 1px RGBA(var(--app-primary-rgb) / 40%);
				z-index: 2;
			}
			.numericPrediction__options-separator{
				background: var(--app-primary);
				height: 100%;
				width: 1px;
				position: absolute;
				z-index: 1;
			}
		}
	}
	.numericPrediction__optionsRow{
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.numericPrediction__options-wrap{
			flex-basis: 45%;
		}
	}
	.numericPrediction__optionsCol{
		flex-direction: column;

		.numericPrediction__options-wrap{
			margin-bottom: 40px;
		}
		.numericPrediction__options-vs{
			top: -40px !important;
			left:0 !important;
			right:0;
			margin-left: auto;
			margin-right: auto;
		}
		.numericPrediction__options-separator{
			width: 100% !important;
			height: 1px !important;
			top: -20px;
		}
	}
}
.phone .numericPrediction{
	width: 100%;
}