.QuizResults{
	> .countContainer {
		text-align: center;
		> .count {
			font-weight: bold;
		}
	}
	> .message {
		text-align: center;
	}

	.poll__element{
		position: relative;
		overflow: hidden;
		>.status{
			width: 10px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		&.isSelected{
			background: RGBA(var(--app-primary-rgb) / 30%);
		}
		&.isCorrect.isSelected{
			.status {
				background: var(--app-success);
			}
		}
		&.isProblem{
			.status {
				background: var(--app-danger);
			}
		}
	}
}
