@use "/src/scss/partials/vars.scss";
.image__choice{
    margin: 5px;
    word-break: break-word;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    border: 2px solid transparent;
    cursor: pointer;
    position: relative;

    &:hover{
        border: 2px solid var(--app-primary);
    }
    .check {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
    }
}
.image__choice-selected{
    background: RGBA(var(--app-primary-rgb) / 20%);
    border: 3px solid var(--app-primary);
}
.image__choice-containerColumn{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img{
        max-width: 200px;
        margin: 0px;
    }
}
.image__choice-containerRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .image__choice{
        flex-grow: 1;
        max-width: 200px;
        min-width: 100px;
    }
    img {
        max-width: 100%;
        min-width: 100px;
    }
}