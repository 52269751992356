.FormFieldSignature {
	position: relative;
	display: flex;
	flex-direction: column;

	.FormFieldSignature__canvas {
		margin: auto;
		padding: 0px;
		width: auto;
		max-width: 100%;
		position: relative;
		border: 1px solid RGBA(var(--app-textColor-rgb) / 30%);
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.FormFieldSignature__clear {
			position: absolute;
			right: 10px;
			top: 10px;
			font-size: 30px;
			z-index: 10;
			cursor: pointer;
		}
	}
}