:root{
	--app-background: #EFF5F5;
	--app-background-rgb: 239 245 245;

	--app-textColor: #000D25;
	--app-textColor-rgb: 91 91 91;

	--app-secondaryTextColor: #F6F8FF;
	--app-secondaryTextColor-rgb: 246 248 255;

	--app-primary: #645CAA;
	--app-primary-rgb: 100 92 170;

	--app-secondary: #EFF5F5;
	--app-secondary-rgb: 239 245 245;

	--app-light: #fff;
	--app-light-rgb: 255 255 255;

	--app-success: #00BFA6;
	--app-success-rgb: 0 191 166;

	--app-danger: #D23369;
	--app-danger-rgb: 210 51 105;

	--app-warning: #FF9F29;
	--app-warning-rgb: 255 159 41;

	--app-radius: 3px;
	--app-logo: url("../../images/logo__black.png");
	--app-logoWhite: url("../../images/logo__black.png");
	--app-logoBlack: url("../../images/logo__white.png");
}