.NumericPredictionOption.has-error input {
	border-color: #D23369;
}

.NumericPredictionOption{
	padding: 10px;
	text-align: center;

	.err__msg{
		display: none;
		font-size: 11px;
		color: #D23369;
	}

	.NumericPredictionOption__input{
		width: 90px;
		display: block;
		margin: auto;
		font-weight: bold;
		font-size: 18px;
		text-align: center;
	}
	.NumericPredictionOption__image{
		max-width: 80%;
		max-height: 6rem;
	}
	.NumericPredictionOption__text{
		margin: 10px;
		font-weight: 550;
	}
}
.NumericPredictionOption.has-error{
	.err__msg{
		display: inline-block;
	}
}