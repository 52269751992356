@use "./partials/vars";

.campaign{
	min-height: 100%;
	width: 100%;
	position: relative;

	.campaign__tint{
		min-height: 100%;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		//background: RGBA(var(--textColor-rgb) / 80%);
	}
	.campaign__wrap{
		height: 100%;
		width: 768px;
		max-width: 100%;
		margin: auto;
		//position: relative;		
		padding-bottom: 80px;
		padding-top: 1px;
	}
	.campaign__revote{
		display: flex;
		flex-direction: column;
		text-align: center;
		width: 100%;
		padding: 10px;
		margin-top: 40px;
	}
}
